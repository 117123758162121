import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';

// Import all the components we've created
import HeroSection from './components/HeroSection';
import HeadshotGallery from './components/HeadshotGallery';
import ResumeTimeline from './components/ResumeTimeline';
import IMDbProfileShowcase from './components/IMDbProfileShowcase';
import FloatingNavigationMenu from './components/FloatingNavigationMenu';
import ContactBookingSection from './components/ContactBookingSection';

const App = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <FloatingNavigationMenu />
      
      <AnimatePresence>
        <motion.section
          id="home"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <HeroSection />
        </motion.section>

        <motion.section
          id="headshots"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true, amount: 0.3 }}
          className="bg-black py-12"
        >
          <HeadshotGallery />
        </motion.section>

        <motion.section
          id="resume"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true, amount: 0.3 }}
          className="bg-gray-800 py-12"
        >
          <ResumeTimeline />
        </motion.section>

        <motion.section
          id="imdb"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true, amount: 0.3 }}
          className="bg-black py-12"
        >
          <IMDbProfileShowcase />
        </motion.section>

        <motion.section
          id="contact"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true, amount: 0.3 }}
          className="bg-gray-800 py-12"
        >
          <ContactBookingSection />
        </motion.section>
      </AnimatePresence>

      <footer className="bg-black text-white py-8 text-center">
        <p>&copy; 2024 Darin Munnell. All rights reserved.</p>
        <a href="https://www.michaelcjenkins.com" className="mt-2 text-sm text-gray-500">Website, Headshots, and Demo Reel by Michael C. Jenkins</a>
      </footer>
    </div>
  );
};

export default App;