import React, { useState, useRef } from 'react';
import { Play, Pause, Volume2, VolumeX } from 'lucide-react';

const HeroSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const modalVideoRef = useRef(null);
  const heroOverlayRef = useRef(null);
  const [hasUnmuted, setHasUnmuted] = useState(false);

  // Sample video URL - replace with your actual video
  const videoUrl = "demo-reel-v3.mov";

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleMute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
    if (!hasUnmuted) {
      heroOverlayRef.current.style.opacity = '0';
      videoRef.current.style.filter = 'brightness(100%)';
      setHasUnmuted(true);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    if (modalVideoRef.current) {
      modalVideoRef.current.currentTime = videoRef.current.currentTime;
      modalVideoRef.current.play();
    }
    videoRef.current.pause();
  };

  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false);
      if (modalVideoRef.current) {
        modalVideoRef.current.pause();
      }
      videoRef.current.play();
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying) {
        setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [isPlaying]);

  return (
    <div className="relative h-screen w-full overflow-hidden bg-black">
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={videoUrl}
        muted={isMuted}
        autoPlay
        loop
        style={{ filter: hasUnmuted ? 'brightness(100%)' : 'brightness(50%)' }}
      />
      <div 
        ref={heroOverlayRef}
        className={`absolute inset-0 flex flex-col justify-center items-center text-white ${hasUnmuted ? 'opacity-0' : ''}`}
      >
        <h1 className="text-6xl font-bold mb-4 uppercase tracking-widest">Darin Munnell</h1>
        <p className="text-2xl mb-8 uppercase tracking-wide">Actor | Stuntman | Performer</p>
        <div className="flex items-center space-x-4">
          <button
            className="bg-white text-black p-2 hover:bg-gray-200 transition-colors"
            onClick={openModal}
          >
            <Play size={24} />
          </button>
          <button
            className="bg-white text-black p-2 hover:bg-gray-200 transition-colors"
            onClick={toggleMute}
          >
            {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
          </button>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full h-1 bg-white bg-opacity-30">
        <div
          className="h-full bg-white transition-all duration-100 ease-linear"
          style={{ width: `${progress}%` }}
        />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeModal}>
          <div className="relative w-full max-w-4xl">
            <video
              ref={modalVideoRef}
              className="w-full"
              src={videoUrl}
              controls
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroSection;