import React, { useState } from 'react';
import { Send, Mail, Phone, MapPin, Calendar } from 'lucide-react';

const ContactBookingSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    date: ''
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionStatus('sending');

    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));

    try {
      const response = await fetch('/send_email.php', {
        method: 'POST',
        body: formDataToSend
      });

      if (response.ok) {
        const result = await response.text();
        setSubmissionStatus('success');
        setFormData({ name: '', email: '', subject: '', message: '', date: '' });
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      setSubmissionStatus('error');
    }
  };

  return (
    <div className="max-w-6xl mx-auto my-16 px-4" id="contact">
      <h2 className="text-3xl font-bold text-center mb-8 uppercase tracking-widest">Connect & Book</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-gray-800 p-8">
          <h3 className="text-2xl font-semibold mb-6 uppercase">Get in Touch</h3>
          <div className="space-y-4">
            <div className="flex items-center">
              <Mail className="mr-4 text-yellow-500" size={24} />
              <span>darinmunnell@gmail.com</span>
            </div>
            <div className="flex items-center">
              <Phone className="mr-4 text-yellow-500" size={24} />
              <span>330-565-6869</span>
            </div>
            <div className="flex items-center">
              <MapPin className="mr-4 text-yellow-500" size={24} />
              <span>Los Angeles, CA</span>
            </div>
            <div className="flex items-center">
              <Calendar className="mr-4 text-yellow-500" size={24} />
              <span>Available for bookings</span>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-8">
          {submissionStatus === 'success' ? (
            <div className="text-green-400 text-center">
              <h3 className="text-2xl font-semibold mb-4">Thank You!</h3>
              <p>Your message has been sent successfully. We'll get back to you soon!</p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-300 uppercase">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full bg-gray-700 border-gray-600 text-white focus:ring-yellow-500 focus:border-yellow-500"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300 uppercase">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full bg-gray-700 border-gray-600 text-white focus:ring-yellow-500 focus:border-yellow-500"
                />
              </div>
              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-300 uppercase">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full bg-gray-700 border-gray-600 text-white focus:ring-yellow-500 focus:border-yellow-500"
                />
              </div>
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-300 uppercase">Preferred Booking Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="mt-1 block w-full bg-gray-700 border-gray-600 text-white focus:ring-yellow-500 focus:border-yellow-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300 uppercase">Message</label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full bg-gray-700 border-gray-600 text-white focus:ring-yellow-500 focus:border-yellow-500"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium text-black bg-yellow-500 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 uppercase"
                disabled={submissionStatus === 'sending'}
              >
                {submissionStatus === 'sending' ? 'Sending...' : (
                  <>Send Message <Send size={18} className="ml-2" /></>
                )}
              </button>
              {submissionStatus === 'error' && (
                <p className="text-red-500 text-center mt-2">An error occurred. Please try again later.</p>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactBookingSection;