import React, { useState, useEffect } from 'react';
import { Menu, X, Home, Film, Camera, FileText, Star, Phone } from 'lucide-react';

const FloatingNavigationMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const [isScrolled, setIsScrolled] = useState(false);

  const menuItems = [
    { name: 'Demo Reel', icon: Film, section: 'home' },
    { name: 'Headshots', icon: Camera, section: 'headshots' },
    { name: 'Resume', icon: FileText, section: 'resume' },
    { name: 'IMDb', icon: Star, section: 'imdb' },
    { name: 'Contact', icon: Phone, section: 'contact' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);

      const sections = menuItems.map(item => item.section);
      for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i]);
        if (section && section.offsetTop <= scrollPosition + 100) {
          setActiveSection(sections[i]);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleItemClick = (section) => {
    setActiveSection(section);
    setIsOpen(false);
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className={`fixed top-4 right-4 z-50 transition-all duration-300 ${isScrolled ? 'bg-gray-900' : 'bg-transparent'}`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`p-3 transition-colors duration-300 ${isScrolled ? 'text-white hover:text-yellow-500' : 'text-white hover:text-yellow-500'}`}
        aria-label="Toggle menu"
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>
      
      <div className={`absolute top-full right-0 mt-2 transition-all duration-300 ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
        <div className="bg-gray-900 shadow-xl">
          {menuItems.map((item) => (
            <button
              key={item.section}
              onClick={() => handleItemClick(item.section)}
              className={`flex items-center w-full px-4 py-2 text-left transition-colors duration-200 ${activeSection === item.section ? 'bg-yellow-500 text-black' : 'text-white hover:bg-gray-800'}`}
            >
              <item.icon size={18} className="mr-2" />
              <span className="uppercase tracking-wide">{item.name}</span>
            </button>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default FloatingNavigationMenu;