import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const HeadshotGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const headshots = [
    { src: "3.png", alt: "1", category: "1" },
    { src: "1.png", alt: "2", category: "2" },
    { src: "4.png", alt: "3", category: "3" },
    { src: "2.png", alt: "4", category: "4" },
    { src: "5.png", alt: "5", category: "5" }, 
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + headshots.length) % headshots.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % headshots.length);
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto px-4" style={{height: '100vh'}}>
      <h2 className="text-3xl font-bold text-center mb-8 uppercase tracking-widest">Headshots</h2>
      <div className="relative overflow-hidden h-[calc(100%-6rem)]">
        <div 
          className="flex transition-transform duration-500 ease-in-out h-full"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {headshots.map((headshot, index) => (
            <div key={index} className="w-full flex-shrink-0 px-2 h-full">
              <img 
                src={headshot.src} 
                alt={headshot.alt} 
                className="w-full h-full object-contain"
              />
              <p className="text-center mt-2 uppercase tracking-wide">{headshot.category}</p>
            </div>
          ))}
        </div>
        <button 
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-black bg-opacity-50 p-2 hover:bg-opacity-75 transition-colors"
          onClick={handlePrev}
        >
          <ChevronLeft size={24} />
        </button>
        <button 
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-black bg-opacity-50 p-2 hover:bg-opacity-75 transition-colors"
          onClick={handleNext}
        >
          <ChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

export default HeadshotGallery;