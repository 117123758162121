import React, { useState } from 'react';
import { Star, TrendingUp, Award, ExternalLink } from 'lucide-react';

const IMDbProfileShowcase = () => {
  const [isHovered, setIsHovered] = useState(false);

  const imdbData = {
    name: "Darin Munnell",
    imdbId: "nm2841376",
    experience: "15 years",
    projects: 10,
    awards: [
      { year: 2022, name: "Best Director for Dying for a Living" },
      { year: 2022, name: "Best Short Film for Dying for a Living" },
    ],
    recentProjects: [
      { name: "Pineapple Insurance", year: 2021, role: "Steve Healy" },
      { name: "Lone Wolf McCray", year: 2016, role: "J.J. McCray" },
    ],
  };

  return (
    <div className="max-w-4xl mx-auto my-16 px-4">
      <h2 className="text-3xl font-bold text-center mb-8 uppercase tracking-widest">IMDb Profile</h2>
      <div 
        className="bg-gray-800 border border-yellow-500 transition-all duration-300 ease-in-out transform hover:scale-105"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-2xl font-bold uppercase">{imdbData.name}</h3>
            <a 
              href={`https://www.imdb.com/name/${imdbData.imdbId}`} 
              target="_blank" 
              rel="noopener noreferrer"
              className="flex items-center text-yellow-500 hover:text-yellow-400 transition-colors"
            >
              View Full Profile <ExternalLink size={16} className="ml-1" />
            </a>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex items-center">
              <Star size={24} className="text-yellow-500 mr-2" />
              <div>
                <p className="text-xl font-semibold">{imdbData.experience}</p>
                <p className="text-sm text-gray-400">acting experience</p>
              </div>
            </div>
            
            <div className="flex items-center">
              <TrendingUp size={24} className="text-green-500 mr-2" />
              <div>
                <p className="text-xl font-semibold">{imdbData.projects}</p>
                <p className="text-sm text-gray-400">acting roles</p>
              </div>
            </div>
            
            <div className="flex items-center">
              <Award size={24} className="text-purple-500 mr-2" />
              <div>
                <p className="text-xl font-semibold">{imdbData.awards.length} Awards</p>
                <p className="text-sm text-gray-400">and nominations</p>
              </div>
            </div>
          </div>
          
          <div className={`mt-6 transition-all duration-300 ease-in-out ${isHovered ? 'opacity-100 max-h-96' : 'opacity-0 max-h-0'} overflow-hidden`}>
            <h4 className="text-lg font-semibold mb-2 uppercase">Recent Projects</h4>
            <ul className="list-disc list-inside">
              {imdbData.recentProjects.map((project, index) => (
                <li key={index} className="mb-1">
                  {project.name} ({project.year}) - {project.role}
                </li>
              ))}
            </ul>
            
            <h4 className="text-lg font-semibold mt-4 mb-2 uppercase">Awards and Nominations</h4>
            <ul className="list-disc list-inside">
              {imdbData.awards.map((award, index) => (
                <li key={index} className="mb-1">
                  {award.year}: {award.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IMDbProfileShowcase;