import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Film, Tv, Theater, GraduationCap } from 'lucide-react';

const ResumeTimeline = () => {
  const [expandedItem, setExpandedItem] = useState(null);

  const resumeItems = [
    {
      year: "Film",
      title: "Dr. Adler in 'Antidote'",
      category: "Film",
      description: "Played Dr. Adler in the film 'Antidote', directed by Peter Daskaloff.",
      icon: Film,
    },
    {
      year: "Film",
      title: "Steve Healy in 'Pineapple Insurance'",
      category: "Film",
      description: "Portrayed Steve Healy in the trailer for 'Pineapple Insurance', directed by Ronnie Lewis Jr.",
      icon: Film,
    },
    {
      year: "Film",
      title: "Corbin in 'The Birth'",
      category: "Film",
      description: "Played Corbin in the short film 'The Birth', directed by Donald McKinney.",
      icon: Film,
    },
    {
      year: "Film",
      title: "Boto in 'Succubus'",
      category: "Film",
      description: "Portrayed Boto in the short film 'Succubus', directed by Donald McKinney.",
      icon: Film,
    },
    {
      year: "Film",
      title: "Band Singer in 'Knight and Day'",
      category: "Film",
      description: "Appeared as a Band Singer in 'Knight and Day', directed by James Mangold.",
      icon: Film,
    },
    {
      year: "Film",
      title: "Utility Stunts in 'Indiana Jones and the Kingdom of the Crystal Skull'",
      category: "Film",
      description: "Performed utility stunts in 'Indiana Jones and the Kingdom of the Crystal Skull', directed by Steven Spielberg.",
      icon: Film,
    },
    {
      year: "Television",
      title: "J.J. McCray in 'Lone Wolf McCray'",
      category: "TV",
      description: "Starred as J.J. McCray in 7 webisodes of 'Lone Wolf McCray', directed by Nicholas Kramer.",
      icon: Tv,
    },
    {
      year: "Television",
      title: "Jimmy's Twin on 'Jimmy Kimmel Live'",
      category: "TV",
      description: "Appeared as Jimmy's Twin on 'Jimmy Kimmel Live', directed by Bobcat Goldthwait.",
      icon: Tv,
    },
    {
      year: "Television",
      title: "Rod Blagojevich on 'Jimmy Kimmel Live'",
      category: "TV",
      description: "Portrayed Rod Blagojevich on 'Jimmy Kimmel Live', directed by Bobcat Goldthwait.",
      icon: Tv,
    },
    {
      year: "Training",
      title: "B.A. Theater",
      category: "Education",
      description: "Earned a B.A. in Theater Production/Performance from Youngstown State University.",
      icon: GraduationCap,
    },
    {
      year: "Training",
      title: "Improv Training at Groundlings",
      category: "Training",
      description: "Completed improv training at the renowned Groundlings theater.",
      icon: Theater,
    },
  ];

  const getCategoryColor = (category) => {
    switch (category) {
      case 'Film': return 'border-blue-500';
      case 'TV': return 'border-green-500';
      case 'Theater': return 'border-purple-500';
      case 'Education': return 'border-red-500';
      case 'Training': return 'border-yellow-500';
      default: return 'border-gray-500';
    }
  };

  return (
    <div className="max-w-4xl mx-auto my-16 px-4">
      <h2 className="text-3xl font-bold text-center mb-8 uppercase tracking-widest">Acting Resume</h2>
      <div className="mb-8 text-center">
        <h3 className="text-2xl font-bold uppercase">Darin Munnell</h3>
        <p className="text-yellow-500">330-565-6869 | darinmunnell@gmail.com</p>
      </div>
      <div className="relative">
        <div className="absolute left-0 h-full w-px bg-gray-700"></div>
        {resumeItems.map((item, index) => (
          <div key={index} className="relative mb-8 pl-8">
            <div className={`absolute left-0 top-0 w-4 h-4 -ml-2 rounded-full bg-gray-900 border-2 ${getCategoryColor(item.category)}`}></div>
            <div className="bg-gray-800 p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-xl font-semibold uppercase">{item.title}</h3>
                <span className="text-sm font-medium text-gray-400">{item.year}</span>
              </div>
              {expandedItem === index && (
                <p className="mt-2 text-gray-300">{item.description}</p>
              )}
              <button 
                className="mt-2 text-yellow-500 hover:text-yellow-400 transition-colors flex items-center"
                onClick={() => setExpandedItem(expandedItem === index ? null : index)}
              >
                {expandedItem === index ? (
                  <span className="flex items-center">Less <ChevronUp size={16} className="ml-1" /></span>
                ) : (
                  <span className="flex items-center">More <ChevronDown size={16} className="ml-1" /></span>
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResumeTimeline;